import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexHome from "@/views/HomePage/IndexHome";
import ShoppingCartIndex from "@/views/ShoppingCart/ShoppingCartIndex";
import Flights from "@/views/Internals/Flight/Flight.vue";
import PaymentError from "@/views/Internals/PaymentError";
import SuccessPayment from "@/views/Internals/SuccessPayment";
import Contact from "@/views/Internals/Contact.vue";
import FlighsHome from "@/views/Internals/Flight/FlighsHome.vue";



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: IndexHome
  },
  {
    path: '/vuelos',
    name: 'vuelos',
    component: FlighsHome
  },
  {
    path: '/vuelos/:flight_permalink',
    name: '/vuelos/:flight_permalink',
    component: Flights
  },
  {
    path: '/cart',
    name: 'cart',
    component: ShoppingCartIndex
  },
  {
    path: '/payment-error',
    name: 'payment-error',
    component: PaymentError
  },
  {
    path: '/success-payment',
    name: 'success-payment',
    component: SuccessPayment
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: Contact
  },

  /*
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/!* webpackChunkName: "about" *!/ '../views/AboutView.vue')
    }
  }*/
]

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router
