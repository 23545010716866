<template>
  <div class="mt-5">
    <h1 class="display-4">Vuelos disponibles</h1>
    <div class="lead">
      Compra ahora con las mejores promociones de <strong>Encuentro Teotihuacan</strong>
    </div>
    <hr>

    <div v-if="!loadingFlights">
      <VueSlickCarousel v-if="homeFlightsList && homeFlightsList.length > 0" v-bind="sliderSettings">
        <div  v-for="(itemFlight, i) in homeFlightsList" >
          <div >
            <b-card

                border-variant="light"
                class="mb-2 mr-5"
            >
              <b-card-title>
                <h3>{{ itemFlight.flight_name }}</h3>
              </b-card-title>
              <b-img-lazy :srcset="itemFlight.gallery[0].path_image" v-bind="mainProps" :src="`/lazy.png`"></b-img-lazy>
              <b-card-text>
                <s class="text-danger">{{ numberFormat(parseInt(itemFlight.flight_prices.first_price_amount), false, '.', ',') }} <small>{{ itemFlight.flight_prices.currency.currency_iso }}</small></s>
                <h2 class="text-primary">{{ numberFormat(parseInt(itemFlight.flight_prices.final_price_amount), false, '.', ',') }} <small>{{ itemFlight.flight_prices.currency.currency_iso }}</small></h2>
                {{ itemFlight.flight_short_description }}
              </b-card-text>

<!--              <b-button href="#" variant="primary">Reservar ahora</b-button>-->
              <b-btn :to="`/vuelos/${itemFlight.flight_permalink}`" variant="primary" class="ml-xl-2" size="lg" block><b-icon icon="arrow-right"></b-icon> Reservar ahora</b-btn>
            </b-card>
          </div>
        </div>
      </VueSlickCarousel>
    </div>

    <div v-else>
      Cargando vuelos, espere...
    </div>
  </div>
</template>

<script>

import VueSlickCarousel from "vue-slick-carousel";

export default {
  name: "ShoppingSlider",
  components: {VueSlickCarousel},
  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: false,
        blankColor: '#bbb',
        width: 500,
        height: 500,
        class: 'my-5'
      },
      loadingFlights: false,
      homeFlightsList: [],
      sliderSettings: {
        "dots": true,
        "infinite": true,
        "slidesToShow": 4,
        "draggable": false,
        "arrows" : true,
        "responsive": [
          {
            "breakpoint": 1400,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 1,
              "infinite": true,
              "dots": true
            }
          },
          {
            "breakpoint": 1080,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "infinite": true,
              "dots": true
            }
          },
        ]
      }
      /*sliderSettings: {
        "dots": true,
        "dotsClass": "slick-dots custom-dot-class",
        "edgeFriction": 0.35,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1
      }*/
    }
  },
  mounted() {
    this.loadingFlights = true
    this.getFlights()
  },
  methods: {
    numberFormat(number, decimals, dec_point, thousands_point) {

      if (number == null || !isFinite(number)) {
        throw new TypeError("number is not valid");
      }

      if (!decimals) {
        var len = number.toString().split('.').length;
        decimals = len > 1 ? len : 0;
      }

      if (!dec_point) {
        dec_point = '.';
      }

      if (!thousands_point) {
        thousands_point = ',';
      }

      number = parseFloat(number).toFixed(decimals);

      number = number.replace(".", dec_point);

      var splitNum = number.split(dec_point);
      splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
      number = splitNum.join(dec_point);

      return number;
    },
    getFlights() {
      this.loadingFlights = true
      this.$http.get(`${process.env.VUE_APP_API_URL}flights`)
          .then((res) => {
            this.homeFlightsList = res.data.data
          }).finally(() => {
        this.loadingFlights = false
      })
    },
    getOldPrice(itemPrice) {
      return itemPrice.flight_prices.first_price_amount
    }
  }
}
</script>

<style scoped>

</style>