<template>
  <div>
    <p class="text-primary mt-1 mb-2"> <b-icon icon="bag-check" aria-hidden="true"></b-icon> Esta experiencia incluye</p>
    <div class="p-3 mt-2 bg-light border-3">
      <div v-for="itemExtra in flightDetails['extras']" class="">
        <div class="media">
          <b-icon class="text-success" icon="check" aria-hidden="true"></b-icon>
          <div class="media-body">
            <p>{{ itemExtra.extra_description_es }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['flightDetails'],
  name: "ExtrasFlight",
  mounted() {
  },
  methods: {

  }
}
</script>