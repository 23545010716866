<template>
  <div class="container">
    <p class="mt-3"><a href="/vuelos"><b-icon icon="arrow-left"></b-icon> Regresar a Vuelos</a></p>

    <div v-if="flightDetails">
      <div v-if="screenIsMobile">
        <FlightInfoMobile :flight-details="flightDetails"></FlightInfoMobile>
      </div>
      <div v-else>
        <FlightInfoDesktop :flight-details="flightDetails"></FlightInfoDesktop>
      </div>
      <hr>
      <SuggestedFlights :random-flights="randomFlights"></SuggestedFlights>
    </div>
    <div class="mb-5 mt-5" v-else>
      <b-row>
        <b-col class="" lg="8" md="12" xs="12">
          <b-img class="mt-3" :src="`/loading-1.png`"></b-img>
          <b-img class="mt-3" :src="`/loading-3.png`"></b-img>
        </b-col>
        <b-col class="order-md-0" lg="4" md="12" xs="12">
          <b-img :src="`/loading-2.png`"></b-img>
        </b-col>
      </b-row>
    </div>


  </div>
</template>

<script>
import FlightInfoDesktop from "@/views/Internals/Flight/FlightInfoDesktop.vue";
import FlightInfoMobile from "@/views/Internals/Flight/FlightInfoMobile.vue";
import SuggestedFlights from "@/views/Internals/Flight/SuggestedFlights.vue";
import {useHead, useSeoMeta} from "@unhead/vue";

export default {
  name: 'Flight',
  components: {SuggestedFlights, FlightInfoMobile, FlightInfoDesktop},
  data() {
    return {
      screenIsMobile: false,
      flightDetails: null,
      randomFlights: null,
    }
  },
  beforeCreate() {

  },
  beforeMount() {

    this.screenIsMobile = this.isMobileScreen()

    //this.getFlightInfo()
  },
  mounted() {

    this.getFlightInfo()


  },
  /*metaInfo: {
    // override the parent template and just use the above title only
    title:  'Vuelos',
    meta: [
      { property: 'og:title', content: `${this.flightDetails.flight_long_name} | ${process.env.VUE_APP_APP_NAME}` },
      { property: 'og:url', content: `${process.env.VUE_APP_APP_URL}/` },
      { property: 'og:image', content: `${process.env.VUE_APP_APP_URL}/vuelos-en-globo-teotihuacan-dos.png` },
      { property: 'og:type', content: `website` },
      { property: 'og:description', content: `Descubre la grandeza de Teotihuacán desde el cielo con CalliTours México. Vive una experiencia única en globo aerostático. Reserva ahora tu vuelo inolvidable.` },
      { property: 'og:locale', content: `es_MX` },
    ]
  },*/
  methods: {
    isMobileScreen() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    getFlightInfo() {
      this.$http.get(`${process.env.VUE_APP_API_URL}flights/${this.$route.params.flight_permalink}`)
          .then((res) => {
            this.flightDetails = res.data.data

          }).finally(() => {
            this.getRandomFlights()

        useSeoMeta({
          title: `${this.flightDetails.flight_long_name} | ${process.env.VUE_APP_APP_NAME}`,
          description: `${this.flightDetails.flight_short_description}`,
          ogDescription: `${this.flightDetails.flight_short_description}`,
          ogTitle: `${this.flightDetails.flight_long_name} | ${process.env.VUE_APP_APP_NAME}`,
          ogImage: this.flightDetails.gallery[0].path_image,
          twitterCard: 'summary_large_image',
        })

            /*useHead({
              title:  `Vuelos | ${process.env.VUE_APP_APP_NAME}`,
              meta: [
                { property: 'og:title', content: `${this.flightDetails.flight_long_name} | ${process.env.VUE_APP_APP_NAME}` },
                { property: 'og:url', content: `${process.env.VUE_APP_APP_URL}/vuelos/${this.flightDetails.flight_permalink}` },
                { property: 'og:image', content: `${process.env.VUE_APP_APP_URL}/vuelos-en-globo-teotihuacan-dos.png` },
                { property: 'og:type', content: `website` },
                { property: 'og:description', content: `${this.flightDetails.flight_short_description}` },
                { property: 'og:locale', content: `es_MX` },
              ]
            })*/
      })
    },
    getRandomFlights() {
      this.$http.get(`${process.env.VUE_APP_API_URL}get-random-three-flights/${this.flightDetails.flight_id}`)
          .then((res) => {
            this.randomFlights = res.data.data
          })
    },
  }
}
</script>