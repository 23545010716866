<template>
  <div id="app">
<!--    <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>
    <router-view/>-->

    <TopBar></TopBar>
    <router-view class=""></router-view>
    <Footer></Footer>
  </div>
</template>
<script>
import TopBar from "@/views/Bars/TopBar";
import Footer from "@/views/Footer";
export default {
  components: {Footer, TopBar},
}
</script>