<template>
  <div>
<!--
    <div class="m-0 alert alert-danger"><strong>Atención.</strong> Esta página se encuentra en modo prueba, ninguna operación o transacción realizada será válida, usted acepta estos términos al navegar. Gracias.</div>
-->
    <div>
      <b-navbar toggleable="lg" type="light" variant="light">
        <b-navbar-brand href="#">
          <b-img height="50" src="/logo-callitours-cafe.png"></b-img>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item v-for="(item, index) in menuItems" :to="item.link">{{ item.name }}</b-nav-item>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
<!--            <b-nav-form>
              <b-form-input size="sm" class="mr-sm-2" placeholder="Ej: Vuelo en globo"></b-form-input>
              <button type="button" class="btn btn-outline-primary my-2 my-sm-0">Buscar</button>
            </b-nav-form>-->

            <b-dropdown-item href="javascript:void(0);" @click="openWhatsappLink()" >
              <b-icon icon="whatsapp"></b-icon> Llámanos o envíanos mensaje ahora
            </b-dropdown-item>

<!--            <b-nav-item-dropdown text="Idioma" right>
              <b-dropdown-item href="#!" @click="switchLanguage('en')">Inglés (EN)</b-dropdown-item>
            </b-nav-item-dropdown>-->


<!--            <b-button :to="'/cart'" variant="primary" class="mb-2 ml-lg-5">
              Pagar ahora <b-icon icon="bag" aria-hidden="true"></b-icon> (1)
            </b-button>-->
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopBar",
  data() {
    return {
      menuItems: []
    }
  },
  methods: {
    openWhatsappLink(){
      let phoneNumber = process.env.VUE_APP_PHONE_CONTACT_NUMBER
      let messageText = encodeURIComponent(`Hola, me gustaría una atención personalizada para mi próximo Vuelo en Globo a través de ${process.env.VUE_APP_APP_NAME} (${process.env.VUE_APP_APP_URL}), ¿me puede brindar más información? Gracias.`)
      window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${messageText}`)
    },
    switchLanguage(language) {
      if(!localStorage.getItem('current_language')) {
        localStorage.setItem('current_language', language)
      }
      console.log('Current Language ---> ' + localStorage.getItem('current_language'))
    }
  },
  mounted() {
    this.menuItems = [
      {
        name: 'Home',
        link: '/'
      },
      {
        name: 'Contacto',
        link: '/contacto'
      },
      {
        name: 'Vuelos',
        link: '/vuelos'
      }
    ]
  }
}
</script>

<style scoped>

</style>