<template>
  <div class="pb-lg-5">
    <h3 class="pb-2">Otros vuelos que podrían interesarte</h3>
    <div class="">
      <b-card v-for="item in randomFlights" class="d-lg-inline-flex mb-3 mr-lg-3 suggested-card border-0 shadow-lg" >
        <b-img-lazy :srcset="item.gallery[0].path_image" v-bind="mainProps" :src="`/lazy.png`"></b-img-lazy>
        <b-card-title class="suggested-card-title mt-2">
          <p class="p-0 m-0">{{ item.flight_name }}</p>
          <p class="p-0 m-0 text-secondary"><small>${{ `${numberFormat(parseInt(item.flight_prices.final_price_amount), false, '.', ',')} / ${item.flight_prices.unit.unit_description}` }}</small></p>
        </b-card-title>
        <b-card-text>
          <b-button :href="`/vuelos/${item.flight_permalink}`" variant="outline-primary" class="btn-lg w-100 mt-3"><b-icon icon="arrow-right-circle"></b-icon> Ver vuelo</b-button>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<style scoped>

</style>
<script>
export default {
  props: ['randomFlights'],
  name: "SuggestedFlights",
  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: false,
        blankColor: '#bbb',
        width: 500,
        height: 500,
        class: ''
      },
    }
  },
  methods: {
    numberFormat(number, decimals, dec_point, thousands_point) {

      if (number == null || !isFinite(number)) {
        throw new TypeError("number is not valid");
      }

      if (!decimals) {
        var len = number.toString().split('.').length;
        decimals = len > 1 ? len : 0;
      }

      if (!dec_point) {
        dec_point = '.';
      }

      if (!thousands_point) {
        thousands_point = ',';
      }

      number = parseFloat(number).toFixed(decimals);

      number = number.replace(".", dec_point);

      var splitNum = number.split(dec_point);
      splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
      number = splitNum.join(dec_point);

      return number;
    },
  }
}
</script>