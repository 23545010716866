<template>
  <div>
    <div class="text-secondary">
      <b-icon icon="share"></b-icon> Compartir | <a target="_blank" :href="getFacebookUrl()"><b-icon icon="facebook"></b-icon></a> <a target="_blank" :href="getTwitterUrl()"><b-icon icon="twitter"></b-icon></a> <a target="_blank" :href="getWhatsappUrl()"><b-icon icon="whatsapp"></b-icon></a> <a @click="copyToClipboard" href="javascript:void(0);"><b-icon icon="link"></b-icon></a>
    </div>
  </div>
</template>
<script>
export default {
  props: ['flightDetails'],
  name: "ShareActions",
  data() {
    return {
      textToShareWhatsapp: '',
      linkToShare: '',
      textToShare: '',
    }
  },
  mounted() {
    this.textToShareWhatsapp = encodeURIComponent(`${this.flightDetails.flight_name} - ${this.flightDetails.flight_short_description} ${this.getUrl()}`)
    this.textToShare = encodeURIComponent(`${this.flightDetails.flight_name} - ${this.flightDetails.flight_short_description}`)
    this.linkToShare = encodeURIComponent(`${this.getUrl()}`)
  },
  methods: {
    getFacebookUrl() {
      return `https://www.facebook.com/sharer/sharer.php?u=${this.linkToShare}`
    },
    getTwitterUrl() {
      return `http://twitter.com/share?text=${this.textToShare}&url=${this.linkToShare}`
    },
    getWhatsappUrl() {
      return `https://api.whatsapp.com/send?text=${this.textToShareWhatsapp}`
    },
    getUrl() {
      return `${process.env.VUE_APP_APP_URL}/vuelos/${this.flightDetails.flight_permalink}`
    },
    copyToClipboard()
    {
      var text = this.getUrl();
      navigator.clipboard.writeText(text).then(function() {
        console.log('Async: Copying to clipboard was successful!');
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
      alert('Link copiado a portapapeles')
    }
  }
}
</script>