<template>
  <div class="container">
    <h2 class="display-2 text-success pb-2">Pago exitoso</h2>

    <div class="alert alert-success" role="alert">
      <h4 class="alert-heading">Gracias por su compra</h4>
      <p>En breve recibirá los detalles de su orden por correo electrónico, o también puede ingresar a su cuenta para verificar el estatus de su compra. Recuerde que puede ponerse en <strong><a
          href="/contacto">contacto</a></strong> con nosotros para cualquier duda o aclaración.</p>
      <hr>
      <a :href="`/`">Volver al inicio</a>
    </div>
  </div>
</template>

<script>
import {useHead} from "@unhead/vue";

export default {
  name: "SuccessPayment",
  mounted() {
    useHead({
      title:  `Pago exitoso | ${process.env.VUE_APP_APP_NAME}`,
      meta: [
        { name: 'robots', content: `noindex` },
      ]
    })
  }
}
</script>

<style scoped>

</style>