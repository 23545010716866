<template>
  <div class="container mb-5 mt-5 ">
    <h1 class="display-4">Carrito de compras</h1>
    <hr>
    <p>Detalles y resumen de la compra</p>
<!--    <b-btn class="mb-2" variant="outline-primary">Modificar compra</b-btn>-->

    <div v-if="currentFlight">

      <b-alert show variant="success">
        <b-icon icon="info-circle"></b-icon> Por favor, revise su compra y añada el número de pasajeros para su vuelo.
      </b-alert>

      <table class="table" >
        <tbody>
        <tr>
          <td>Servicio:</td>
          <td><strong>{{ currentFlight.flightName }}</strong></td>
        </tr>
        <tr>
          <td>Moneda:</td>
          <td><strong>MXN (pesos mexicanos)</strong></td>
        </tr>
        <tr>
          <td>Este vuelo se vende por:</td>
          <td><strong>{{ currentFlight.flight_prices.unit.unit_description }}</strong></td>
        </tr>
        <tr>
          <td>Precio del servicio (se vende por {{ `${currentFlight.flight_prices.unit.unit_description}` }}):</td>
          <td><strong>{{ numberFormat(parseInt(currentFlight.flight_prices.final_price_amount), ',', '.') }}</strong></td>
        </tr>
        <tr>
          <td>Número de pax:</td>
          <td>
            <div>
              <label for="sb-wrap">Especifique cuántas <strong>{{ `${currentFlight.flight_prices.unit.unit_description}(s)` }}</strong> desea añadir</label>
              <b-form-spinbutton v-model="quantitiesNumber" id="sb-wrap" wrap min="1" max="10" placeholder="--"></b-form-spinbutton>
            </div>
          </td>
        </tr>
        <tr>
          <td>Subtotal:</td>
          <td>
            {{ numberFormat(shoppingCartAmount, false, '.', ',') }}
          </td>
        </tr>
        <tr>
          <td>Total:</td>
          <td>
            $ {{ numberFormat(shoppingCartAmount, false, '.', ',') }}
          </td>
        </tr>
        </tbody>
      </table>
      <hr>

      <form :action="formCheckoutUrl" method="POST">
        <input type="hidden" name="quantities" v-model="quantitiesNumber" >
        <input type="hidden" name="flight-id" v-model="currentFlight.id" >
        <b-btn variant="success" v-if="isMobileScreen()" type="submit" block size="lg" ><b-icon icon="arrow-right" ></b-icon> Finalizar compra</b-btn>
        <b-btn variant="success" v-else type="submit" ><b-icon icon="arrow-right"></b-icon> Finalizar compra</b-btn>
      </form>
    </div>

    <div v-else>
      <b-alert show variant="primary" dismissible>
        <b-icon icon="info-circle"></b-icon> No se encontraron servicios agregados al carrito. <a href="/vuelos">Ver Vuelos</a>
      </b-alert>
    </div>


<!--    <h1 class="display-3">Forma de pago</h1>
    <hr>

    <b-card bg-variant="light">
      <b-form-group
          label-cols-lg="3"
          label="Shipping Address"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
      >
        <b-form-group
            label="Street:"
            label-for="nested-street"
            label-cols-sm="3"
            label-align-sm="right"
        >
          <b-form-input id="nested-street"></b-form-input>
        </b-form-group>

        <b-form-group
            label="City:"
            label-for="nested-city"
            label-cols-sm="3"
            label-align-sm="right"
        >
          <b-form-input id="nested-city"></b-form-input>
        </b-form-group>

        <b-form-group
            label="State:"
            label-for="nested-state"
            label-cols-sm="3"
            label-align-sm="right"
        >
          <b-form-input id="nested-state"></b-form-input>
        </b-form-group>

        <b-form-group
            label="Country:"
            label-for="nested-country"
            label-cols-sm="3"
            label-align-sm="right"
        >
          <b-form-input id="nested-country"></b-form-input>
        </b-form-group>

        <b-form-group
            label="Ship via:"
            label-cols-sm="3"
            label-align-sm="right"
            class="mb-0"
            v-slot="{ ariaDescribedby }"
        >
          <b-form-radio-group
              class="pt-2"
              :options="['Air', 'Courier', 'Mail']"
              :aria-describedby="ariaDescribedby"
          ></b-form-radio-group>
        </b-form-group>
      </b-form-group>
    </b-card>-->

  </div>
</template>

<script>

import {useHead, useSeoMeta} from "@unhead/vue";

export default {
  name: "ShoppingCartIndex",
  data(){
    return {
      currentFlight: null,
      shoppingCartAmount: 0,
      quantitiesNumber: 1,
      formCheckoutUrl: '',
    }
  },
  mounted() {
    this.formCheckoutUrl = `${process.env.VUE_APP_API_URL}proceed-checkout`

    if(this.$route.params.flight) {
      this.currentFlight = this.$route.params.flight
      console.log('ShoppingCartIndex')
      console.log(this.currentFlight)
      this.calculateAmount()
    }

    useHead({
      title:  `Checkout | ${process.env.VUE_APP_APP_NAME}`,
      meta: [
        { name: 'robots', content: `noindex` },
      ]
    })
  },
  watch: {
    quantitiesNumber: function(val, oldVal) {
      this.calculateAmount()
    }
  },
  methods: {
    isMobileScreen() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    proceedCheckout() {
      let params = {
        quantities: this.quantitiesNumber,
        'flight-id': this.currentFlight.flightId
      }
      console.log(params)
      this.$http.post(`http://localhost:8000/api/proceed-checkout`, params).then((res) => {

      }).catch((err) => {
        console.log(err)
      })
    },
    calculateAmount() {
      this.shoppingCartAmount = parseFloat(this.currentFlight.flight_prices.final_price_amount) * parseInt(this.quantitiesNumber)
    },
    numberFormat(number, decimals, dec_point, thousands_point) {

      if (number == null || !isFinite(number)) {
        throw new TypeError("number is not valid");
      }

      if (!decimals) {
        var len = number.toString().split('.').length;
        decimals = len > 1 ? len : 0;
      }

      if (!dec_point) {
        dec_point = '.';
      }

      if (!thousands_point) {
        thousands_point = ',';
      }

      number = parseFloat(number).toFixed(decimals);

      number = number.replace(".", dec_point);

      var splitNum = number.split(dec_point);
      splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
      number = splitNum.join(dec_point);

      return number;
    }
  }
}
</script>

<style scoped>

</style>