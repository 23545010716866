<template>
  <div>
    <b-row>
      <b-col xs="12" md="6">
        <b-img class="d-block w-100 h-auto mb-5" :alt="homeDetails.altDesciptions.logoImage" fluid src="./logo-callitours-amarillo.png"></b-img>
      </b-col>
      <b-col xs="12" md="6">
        <h2>{{ homeDetails.titleDescriptions.encuentroTeotihuacan }}</h2>

        <b-tabs content-class="mt-3">
          <b-tab title="Misión" active>
            <p>En CalliTours Teotihuacan, nuestra misión es proporcionar experiencias únicas e inolvidables a nuestros clientes, ofreciendo vuelos en globo sobre la fascinante zona arqueológica de Teotihuacán en México. Nos esforzamos por crear momentos mágicos y emocionantes, donde nuestros pasajeros puedan maravillarse con las impresionantes vistas panorámicas mientras flotan suavemente sobre una de las civilizaciones más antiguas de Mesoamérica.</p>

            <p>Nuestro compromiso con la seguridad, la calidad y la excelencia en el servicio es primordial en todo lo que hacemos. Desde el momento en que nuestros clientes reservan su vuelo hasta el momento en que aterrizan de vuelta en tierra firme, nos esforzamos por superar sus expectativas y brindarles una experiencia verdaderamente excepcional. Nos comprometemos a operar con los más altos estándares de seguridad, utilizando equipos de alta calidad y contando con pilotos experimentados y certificados que garantizan un viaje seguro y placentero para todos nuestros pasajeros.</p>

            <p>Además, estamos comprometidos con la preservación y protección del patrimonio cultural y natural de Teotihuacán. Reconocemos la importancia de este sitio arqueológico tanto a nivel nacional como internacional, y nos comprometemos a operar de manera responsable y sostenible, minimizando nuestro impacto ambiental y respetando las regulaciones locales y los derechos de las comunidades locales.</p>

            <p>En resumen, nuestra misión en CalliTours Teotihuacan es proporcionar a nuestros clientes una experiencia única y emocionante que les permita descubrir la belleza y la grandeza de Teotihuacán desde una perspectiva completamente nueva, mientras nos esforzamos por ser líderes en la industria de los viajes en globo en México y ser reconocidos por nuestra dedicación a la seguridad, la calidad y la excelencia en el servicio.</p>

          </b-tab>
          <b-tab title="Visión">
            <p>En CalliTours Teotihuacan, nuestra visión es convertirnos en la empresa líder en la industria de los viajes en globo en México, destacando por nuestra innovación, calidad y compromiso con la excelencia en el servicio. Nos esforzamos por ser reconocidos a nivel nacional e internacional como el destino preferido para aquellos que buscan una experiencia única y emocionante sobre las antiguas pirámides de Teotihuacán.</p>

            <p>Nos visualizamos como una empresa en constante crecimiento y evolución, expandiendo nuestras operaciones para ofrecer una variedad de servicios y experiencias que satisfagan las necesidades y deseos de nuestros clientes. Desde vuelos en globo al amanecer hasta vuelos al atardecer con cenas románticas, nos esforzamos por ofrecer una gama diversa de opciones que permitan a nuestros clientes personalizar su experiencia y crear recuerdos inolvidables.</p>

            <p>Además, aspiramos a ser un modelo a seguir en términos de responsabilidad social y ambiental. Nos comprometemos a colaborar estrechamente con las comunidades locales para impulsar el desarrollo sostenible y contribuir al bienestar de quienes viven en la región. También nos comprometemos a continuar invirtiendo en tecnologías y prácticas que nos permitan operar de manera más sostenible y reducir nuestro impacto ambiental.</p>

            <p>En última instancia, nuestra visión en CalliTours Teotihuacan es inspirar a las personas a explorar, descubrir y apreciar la belleza y la historia de Teotihuacán desde una perspectiva única y emocionante, mientras nos esforzamos por ser líderes en la industria y elevar continuamente los estándares de calidad y servicio en la industria de los viajes en globo en México.</p>
          </b-tab>
        </b-tabs>



<!--        <b-button variant="outline-primary" size="lg">{{ homeDetails.buttonDescriptions.shopNow }}</b-button>-->
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "SectionShopNow",
  data() {
    return {
      homeDetails: {
        altDesciptions: {
          logoImage: process.env.VUE_APP_APP_NAME
        },
        titleDescriptions: {
          encuentroTeotihuacan: process.env.VUE_APP_APP_NAME
        },
        buttonDescriptions: {
          shopNow: 'Comprar ahora'
        }
      }
    }
  }
}
</script>

<style scoped>

</style>