<template>
  <div class="container mb-5 mt-5 ">
    <h1 class="display-4">Contacto</h1>
    <p class="mt-3"><a href="/"><b-icon icon="arrow-left"></b-icon> Regresar al inicio</a></p>

    <hr>
    <div>

      <p><a class="text-secondary" href="#!" @click="openWhatsappLink()"><b-icon icon="whatsapp"></b-icon> ¿Prefieres contactarnos por Whatsapp? ¡Estamos las 24 horas disponibles para ti!</a></p>

      <div v-if="showSuccessMessage">
        <b-alert class="mb-3" show variant="success"><strong>El mensaje se envió correctamente</strong>, en el transcurso de unas horas nos pondremos en contacto con usted.
          <br>Gracias.</b-alert>
      </div>

      <b-form-group
          id="nombre"
          label="Nombre completo*"
          type="text"
      >
        <b-form-input placeholder="Pedro Pérez" id="input-1" v-model="formContact.fullName" trim></b-form-input>
      </b-form-group>

      <b-form-group
          id="email"
          label="Email de contacto*"
          type="email"
      >
        <b-form-input placeholder="example@domain.com" id="input-1" v-model="formContact.email" trim></b-form-input>
      </b-form-group>

      <b-form-group
          id="email"
          label="Teléfono de contacto (personal, whatsapp, trabajo, etc)*"
          type="email"
      >
        <b-form-input placeholder="+525566332211" id="input-1" v-model="formContact.contactNumber" trim></b-form-input>
      </b-form-group>


      <label for="">Mensaje ({{formContact.message.length}}/249)*:</label>
      <b-form-textarea
          id="textarea"
          v-model="formContact.message"
          placeholder="Escriba su mensaje aquí, máximo 250 caracteres"
          rows="6"
          max-rows="6"
          :maxlength="249"
      ></b-form-textarea>

      <div class="mt-3">
        <b-button :disabled="loadingSendingForm" @click="sendForm()" block variant="primary" class="shadow-sm">Enviar</b-button>
      </div>



    </div>
  </div>
</template>
<script>
import {useHead, useSeoMeta} from "@unhead/vue";

export default {
  name: "Contact",
  data() {
    return {
      loadingSendingForm: false,
      showSuccessMessage: false,
      formContact: {
        fullName: '',
        email: '',
        contactNumber: '',
        message: '',
        serviceOption: null,
      }
    }
  },
  mounted() {

    useSeoMeta({
      title: `Contacto | ${process.env.VUE_APP_APP_NAME}`,
      description: `¿Listo para elevar tu experiencia en Teotihuacán? Contáctanos a través de nuestro formulario y prepárate para un emocionante vuelo en globo con CalliTours México. ¡Te esperamos!`,
      ogDescription: `¿Listo para elevar tu experiencia en Teotihuacán? Contáctanos a través de nuestro formulario y prepárate para un emocionante vuelo en globo con CalliTours México. ¡Te esperamos!`,
      ogTitle: `Contacto | ${process.env.VUE_APP_APP_NAME}`,
      ogImage: `${process.env.VUE_APP_APP_URL}/vuelos-en-globo-teotihuacan-dos.png` ,
      twitterCard: 'summary_large_image',
    })
  },
  methods: {
    openWhatsappLink(){
      let phoneNumber = process.env.VUE_APP_PHONE_CONTACT_NUMBER
      let messageText = encodeURIComponent('Hola, me gustaría una atención personalizada para mi próximo Vuelo en Globo a través de CalliTours México (https://www.callitours.com.mx), ¿me puede brindar más información? Gracias.')
      window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${messageText}`)
    },
    sendForm() {
      if(
          (this.formContact.fullName.trim().length <= 0|| this.formContact.fullName.trim().length < 5) ||
          (this.formContact.email.trim().length <= 0|| this.formContact.email.trim().length < 5) ||
          (this.formContact.contactNumber.trim().length <= 0|| this.formContact.contactNumber.trim().length < 5) ||
          (this.formContact.message.trim().length <= 0|| this.formContact.message.trim().length < 5)
      ) {
        alert('Todos los campos marcados con (*) son obligatorios, por favor vuelva a intentarlo')
        return false
      }

      this.loadingSendingForm = true
      this.showSuccessMessage = false
      this.$http.post(`${process.env.VUE_APP_API_URL}contact`, {
        'full_name': this.formContact.fullName,
        'email_contact': this.formContact.email,
        'phone_contact': this.formContact.contactNumber,
        'message': this.formContact.message,
      }).then((res) => {
        this.formContact = {
          fullName: '',
          email: '',
          contactNumber: '',
          message: '',
          serviceOption: null,
        }
        this.showSuccessMessage = true

      }).catch((e) => {
        alert('Algunos campos son inválidos, por corríjalos y vuelva a intentarlo')
      }).finally(() => {
        this.loadingSendingForm = false
      })
    }
  }
}
</script>
