<template>
  <div>
    <div class="pt-0 pl-5 pr-5 pb-5">
      <ShoppingSlider></ShoppingSlider>
    </div>
    <hr>
    <div class="p-5">
      <SliderOffers></SliderOffers>
    </div>
    <hr>
    <div class="p-5">
      <SectionShopNow></SectionShopNow>
    </div>

  </div>
</template>

<script>
import SectionShopNow from "@/views/HomePage/InformationSite";
import SliderOffers from "@/views/HomePage/SliderOffers";
import ShoppingSlider from "@/views/HomePage/ShoppingSlider";
import {useHead, useSeoMeta} from "@unhead/vue";

export default {
  name: "Index",
  components: {ShoppingSlider, SliderOffers, SectionShopNow},
  /*metaInfo: {
    // override the parent template and just use the above title only
    title:  'Home',
    meta: [
      { property: 'og:title', content: `Home | ${process.env.VUE_APP_APP_NAME}` },
      { property: 'og:url', content: `${process.env.VUE_APP_APP_URL}/` },
      { property: 'og:image', content: `${process.env.VUE_APP_APP_URL}/vuelos-en-globo-teotihuacan-dos.png` },
      { property: 'og:type', content: `website` },
      { property: 'og:description', content: `Embárcate en una aventura inolvidable sobre Teotihuacán con CalliTours México. Descubre la magia ancestral desde el cielo en nuestros emocionantes vuelos en globo. ¡Reserva hoy mismo!` },
      { property: 'og:locale', content: `es_MX` },
    ]
  },*/
  data() {
    return {
    }
  },
  mounted() {
    useSeoMeta({
      title: `Home | ${process.env.VUE_APP_APP_NAME}`,
      description: `Embárcate en una aventura inolvidable sobre Teotihuacán con CalliTours México. Descubre la magia ancestral desde el cielo en nuestros emocionantes vuelos en globo. ¡Reserva hoy mismo!`,
      ogDescription: `Embárcate en una aventura inolvidable sobre Teotihuacán con CalliTours México. Descubre la magia ancestral desde el cielo en nuestros emocionantes vuelos en globo. ¡Reserva hoy mismo!`,
      ogTitle: `Home | ${process.env.VUE_APP_APP_NAME}`,
      ogImage: `${process.env.VUE_APP_APP_URL}/vuelos-en-globo-teotihuacan-dos.png` ,
      twitterCard: 'summary_large_image',
    })
  }
}
</script>

<style scoped>

</style>