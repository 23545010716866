<template>
  <div class="container mb-5 mt-5 ">
    <h1 class="display-4">Vuelos</h1>

    <p class="mt-3"><a href="/"><b-icon icon="arrow-left"></b-icon> Regresar al inicio</a></p>
    <hr>
    <b-row>
      <b-col class="mb-3" lg="4" md="2" sm="12" v-for="(itemFlight, i) in flightsList">
        <b-card
            class="mb-2 mr-5 border-0 shadow-lg"
        >
          <b-img-lazy :srcset="itemFlight.gallery[0].path_image" v-bind="mainProps" :src="`/lazy.png`"></b-img-lazy>
          <b-card-title class="m-0 p-0">
            {{ itemFlight.flight_name }}
          </b-card-title>
          <b-card-text>
            <s class="text-danger">{{ numberFormat(parseInt(itemFlight.flight_prices.first_price_amount), false, '.', ',') }} <small>{{ itemFlight.flight_prices.currency.currency_iso }}</small></s>
            <h2 class="text-primary">{{ numberFormat(parseInt(itemFlight.flight_prices.final_price_amount), false, '.', ',') }} <small>{{ itemFlight.flight_prices.currency.currency_iso }}</small></h2>
            {{ itemFlight.flight_short_description }}
          </b-card-text>

<!--          <b-button href="#" variant="primary">Reservar ahora</b-button>-->
          <b-button :to="`/vuelos/${itemFlight.flight_permalink}`" variant="primary" class="ml-xl-2" block><b-icon icon="arrow-right"></b-icon> Reservar ahora</b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {useHead, useSeoMeta} from "@unhead/vue";

export default {
  name: "FlighsHome",
  beforeMount() {
    this.getFlights()
  },
  mounted() {

    useSeoMeta({
      title: `Vuelos | ${process.env.VUE_APP_APP_NAME}`,
      description: `Descubre la grandeza de Teotihuacán desde el cielo con CalliTours México. Vive una experiencia única en globo aerostático. Reserva ahora tu vuelo inolvidable.`,
      ogDescription: `Descubre la grandeza de Teotihuacán desde el cielo con CalliTours México. Vive una experiencia única en globo aerostático. Reserva ahora tu vuelo inolvidable.`,
      ogTitle: `Vuelos | ${process.env.VUE_APP_APP_NAME}`,
      ogImage: `${process.env.VUE_APP_APP_URL}/vuelos-en-globo-teotihuacan-dos.png` ,
      twitterCard: 'summary_large_image',
    })

    /*useHead({
      title:  `Vuelos | ${process.env.VUE_APP_APP_NAME}`,
      meta: [
        { property: 'og:title', content: `Vuelos | ${process.env.VUE_APP_APP_NAME}` },
        { property: 'og:url', content: `${process.env.VUE_APP_APP_URL}/vuelos` },
        { property: 'og:image', content: `${process.env.VUE_APP_APP_URL}/vuelos-en-globo-teotihuacan-dos.png` },
        { property: 'og:type', content: `website` },
        { property: 'og:description', content: `Descubre la grandeza de Teotihuacán desde el cielo con CalliTours México. Vive una experiencia única en globo aerostático. Reserva ahora tu vuelo inolvidable.` },
        { property: 'og:locale', content: `es_MX` },
      ]
    })*/
  },
  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: false,
        blankColor: '#bbb',
        width: 500,
        height: 500,
      },
      loadingFlights: false,
      flightsList: [],
    }
  },
  methods: {
    numberFormat(number, decimals, dec_point, thousands_point) {

      if (number == null || !isFinite(number)) {
        throw new TypeError("number is not valid");
      }

      if (!decimals) {
        var len = number.toString().split('.').length;
        decimals = len > 1 ? len : 0;
      }

      if (!dec_point) {
        dec_point = '.';
      }

      if (!thousands_point) {
        thousands_point = ',';
      }

      number = parseFloat(number).toFixed(decimals);

      number = number.replace(".", dec_point);

      var splitNum = number.split(dec_point);
      splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
      number = splitNum.join(dec_point);

      return number;
    },
    getFlights() {
      this.loadingFlights = true
      this.$http.get(`${process.env.VUE_APP_API_URL}flights`)
          .then((res) => {
            this.flightsList = res.data.data
          }).finally(() => {
        this.loadingFlights = false
      })
    },
    getOldPrice(itemPrice) {
      return itemPrice.flight_prices.first_price_amount
    }
  }
}
</script>