<template>
  <div>
    <b-carousel
        id="carousel-fade"
        style="text-shadow: 0px 0px 2px #000"
        fade
        indicators
        img-width="1024"
        img-height="480"
        :interval="3000 "
    >
      <b-carousel-slide
          text="Explora la majestuosidad de Teotihuacán"
          img-src="./vuelos-en-globo-teotihuacan-uno.png"
      ></b-carousel-slide>
      <b-carousel-slide
          text="Descubre Teotihuacán desde una perspectiva única"
          img-src="./vuelos-en-globo-teotihuacan-dos.png"
      ></b-carousel-slide>
      <b-carousel-slide
          text="Vive la grandeza de Teotihuacán desde las alturas"
          img-src="./vuelos-en-globo-teotihuacan-tres.png"
      ></b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: "SliderOffers"
}
</script>

<style scoped>

</style>