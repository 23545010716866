<template>
  <div class="bg-light text-dark">
    <div class="container">
      <div class="p-5" >
        <b-row class="">
          <b-col lg="6" md="12">
            <h4>Vuelos</h4>
            <ul>
              <li class="p-2" v-for="item in flightsList">
                <a class="text-secondary" :href="`/vuelos/${item.flight_permalink}`">{{ item.flight_name }}</a>
              </li>
            </ul>
          </b-col>

          <b-col lg="6" md="12">
            <h4>Contacto</h4>
            <h5>Atención 24 horas.</h5>
            <ul>
              <li class="p-2">
                <a class="text-secondary" :href="`tel:+${phoneContactNumber}`"><b-icon icon="telephone"></b-icon> México. {{ phoneCleanedNumber }}</a>
              </li>
              <li class="p-2">
                <a class="text-secondary" href="#!" @click="openWhatsappLink()"><b-icon icon="whatsapp"></b-icon> Whatsapp Ventas.</a>
              </li>
              <li class="p-2">
                <a class="text-secondary" href="mailto:contacto@callitours.com.mx"><b-icon icon="envelope"></b-icon> contacto@callitours.com.mx</a>
              </li>
              <li class="p-2">
                <a class="text-secondary" href="/contacto"><b-icon icon="chat"></b-icon> Formulario de contacto</a>
              </li>
            </ul>
            <hr>
            <h4>Menú</h4>
            <ul>
              <li class="p-2" v-for="itemM in menuItems">
                <a class="text-secondary" :href="itemM.link"><b-icon icon="home"></b-icon> {{ itemM.name }}</a>
              </li>
            </ul>
          </b-col>
        </b-row>

        <hr>
        <div class="d-block w-100">
          <p class="">© 2024 Encuentro Teotihuacan</p>
          <p class="font-weight-bold">Formas de pago</p>
          <b-img height="50" src="/payments-logos.png"></b-img>
          <hr>
          <b-img height="50" src="/stripe.svg"></b-img>
          <small><p>Este sitio ocupa la mejor tecnología para el procesamiento de pagos seguros en internet con Stripe</p></small>
        </div>
        <!--      <p class="text-center">VISA | MASTERCARD | OXXO | TIENDAS DE CONVENIENCIA</p>-->
      </div>
    </div>

    <!--Floating button-->
    <FloatingButton></FloatingButton>
  </div>
</template>

<script>
import FloatingButton from "@/views/Internals/FloatingButton.vue";

export default {
  name: "Footer",
  components: {FloatingButton},
  data() {
    return {
      phoneContactNumber: '',
      phoneCleanedNumber: '',
      flightsList: null,
      menuItems: null
    }
  },
  mounted() {
    this.phoneContactNumber = process.env.VUE_APP_PHONE_CONTACT_NUMBER
    this.phoneCleanedNumber = process.env.VUE_APP_PHONE_CLEANED_CONTACT_NUMBER
    this.getFlights()

    this.menuItems = [
      {
        name: 'Home',
        link: '/'
      },
      {
        name: 'Contacto',
        link: '/contacto'
      },
      {
        name: 'Vuelos',
        link: '/vuelos'
      }
    ]
  },
  methods: {
    openWhatsappLink(){
      let phoneNumber = process.env.VUE_APP_PHONE_CONTACT_NUMBER
      let messageText = encodeURIComponent('Hola, me gustaría una atención personalizada para mi próximo Vuelo en Globo a través de CalliTours México (https://www.callitours.com.mx), ¿me puede brindar más información? Gracias.')
      window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${messageText}`)
    },
    getFlights() {
      this.$http.get(`${process.env.VUE_APP_API_URL}flights`)
          .then((res) => {

            this.flightsList = res.data.data
          })
    }
  }
}
</script>

<style scoped>

</style>