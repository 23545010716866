import Vue from 'vue'
import App from './App.vue'
import VueSlickCarousel from 'vue-slick-carousel'
import router from './router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { createHead } from '@unhead/vue'
import { UnheadPlugin } from '@unhead/vue/vue2'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import axios from 'axios';
import './assets/global.css'

// Add it to Vue prototype (use any variable you like, preferrably prefixed with a $).

Vue.prototype.$http = axios;
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueSlickCarousel)

Vue.config.productionTip = false
const head = createHead()
Vue.use(UnheadPlugin)

new Vue({
  unhead: head,
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
