<template>
  <div class="container mb-5 mt-5 ">
    <h2 class="display-4 text-danger pb-3">Ocurrió un erorr al procesar su pago</h2>

    <div class="alert alert-danger" role="alert">
      <h4 class="alert-heading">El pago no pudo ser procesado</h4>
      <p>No pudimos procesar el pago de su servicio, le sugerimos intentar otra forma de pago o intentarlo nuevamente más tarde. Si el problema persiste, puede ponerse en
        <a href="/contacto">contacto con nosotros</a></p>
      <hr>
      <a href="/">Volver al inicio</a>
    </div>
  </div>
</template>

<script>
import {useHead} from "@unhead/vue";

export default {
  name: "PaymentError",
  mounted() {
    useHead({
      title:  `Error al pagar | ${process.env.VUE_APP_APP_NAME}`,
      meta: [
        { name: 'robots', content: `noindex` },
      ]
    })
  }
}
</script>

<style scoped>

</style>