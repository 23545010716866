<template>
  <div>

    <div v-if="!floatingButtonIsHidden" class="floating-button">
      <div class="button-container">
        <table>
          <tr>
            <td width="10%"><b-img class="" src="/whatsapp-icon.png"></b-img></td>
            <td width="80%">
              <div class="text-container" @click="openWhatsappLink()">
                <p class="pl-3 m-0 header-title "> <strong>Contáctanos ahora</strong></p>
                <p class="pl-3 m-0 header-subtitle ">¿Dudas sobre vuelos en globo en Teotihuacán? ¡Contáctanos en WhatsApp y reserva ahora!</p>
              </div>
            </td>
            <td style="vertical-align: top;" width="10%">
              <div @click="floatingButtonIsHidden = true" class="header-button text-right float-right"><b-icon icon="x"></b-icon></div>
            </td>
          </tr>
        </table>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FloatingButton",
  data() {
    return {
      floatingButtonIsHidden: false,
    }
  },
  methods: {
    openWhatsappLink(){
      let phoneNumber = process.env.VUE_APP_PHONE_CONTACT_NUMBER
      let messageText = encodeURIComponent('Hola, me gustaría una atención personalizada para mi próximo Vuelo en Globo a través de CalliTours México (https://www.callitours.com.mx), ¿me puede brindar más información? Gracias.')
      window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${messageText}`)
    },
  }
}
</script>