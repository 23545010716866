<template>
  <div class="" v-if="flightDetails">
    <div class="">


      <h1 class="mt-3">{{ flightDetails.flight_long_name }}</h1>
      <div>
        <ShareActions :flight-details="flightDetails"></ShareActions>
      </div>
      <hr>
      <span class="badge badge-light"><b-icon icon="info-circle"></b-icon> Precio por {{ flightDetails.flight_prices.unit.unit_description }}</span>
      <p class="p-0 m-0 text-danger">Descuento de <s>${{numberFormat(parseInt(flightDetails.flight_prices.first_price_amount), false, '.', ',')}}</s> a sólo:</p>
      <h2 class="">${{numberFormat(parseInt(flightDetails.flight_prices.final_price_amount), false, '.', ',')}} <small>MXN</small></h2>

      <b-img-lazy :alt="getAltImageFlight(flightDetails)" :srcset="flightDetails.gallery[0].path_image" v-bind="mainProps" :src="`/lazy.png`"></b-img-lazy>

      <hr>

      <p v-html="flightDetails.flight_description"></p>
      <hr>

      <b-btn @click="payFlightNow()" type="button" variant="success" size="lg" block><b-icon icon="arrow-right"></b-icon> Pagar ahora</b-btn>

      <div class="mt-5">
        <p>Medios de pago</p>
        <b-img height="50" src="/payments-logos.png"></b-img>
      </div>

      <ExtrasFlight :flight-details="flightDetails"></ExtrasFlight>

    </div>
  </div>
</template>

<script>
import ExtrasFlight from "@/views/Internals/Flight/ExtrasFlight.vue";
import ShareActions from "@/views/Internals/Flight/ShareActions.vue";

export default {
  components: {ShareActions, ExtrasFlight},
  props: ['flightDetails'],
  name: 'FlightInfoMobile',
  data() {
    return {
      //flightDetails: null
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: false,
        blankColor: '#bbb',
        width: 500,
        height: 500,
        class: ''
      },
    }
  },
  mounted() {
    console.log(this.isMobileScreen())

    /*this.flightDetails = {
      flight_id: 1,
      flightName: 'Vuelo Privado',
      flightPrice: 8400,
      flightUnit: {
        unitName: 'pareja',
      },
      flightExtras: [
          'Experiencia en globo aerostático exclusivo',
          'Coffee break de bienvenida',
          'Diploma de vuelo personalizado',
          'Botella conmemorativa cava Freixenet para Brindar con el Capitán',
          'Desayuno en Restaurante La higuera',
          'Seguro de aviación por pasajero',
          'Música a bordo del vuelo',
          'Despliegue de lona: TE AMO, FELIZ CUMPLEAÑOS, FELIZ ANIVERSARIO',
          'Visita al Taller de Obsidiana y Artesanía',
      ],
    }*/
    //console.log('flight_id ---->' + this.$route.params.flight_id)

    //this.getFlightInfo()
  },
  methods: {
    getAltImageFlight(flightDetails){
      return `${process.env.VUE_APP_APP_NAME} - ${flightDetails.flight_long_name}`
    },
    isMobileScreen() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    numberFormat(number, decimals, dec_point, thousands_point) {

      if (number == null || !isFinite(number)) {
        throw new TypeError("number is not valid");
      }

      if (!decimals) {
        var len = number.toString().split('.').length;
        decimals = len > 1 ? len : 0;
      }

      if (!dec_point) {
        dec_point = '.';
      }

      if (!thousands_point) {
        thousands_point = ',';
      }

      number = parseFloat(number).toFixed(decimals);

      number = number.replace(".", dec_point);

      var splitNum = number.split(dec_point);
      splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
      number = splitNum.join(dec_point);

      return number;
    },
    /*getFlightInfo() {

      this.$http.get(`${process.env.VUE_APP_API_URL}flights/${this.$route.params.flight_permalink}`)
          .then((res) => {
            this.flightDetails = res.data.data
            this.flightDetails['extras'] = [
              'Experiencia en globo aerostático exclusivo',
              'Coffee break de bienvenida',
              'Diploma de vuelo personalizado',
              'Botella conmemorativa cava Freixenet para Brindar con el Capitán',
              'Desayuno en Restaurante La higuera',
              'Seguro de aviación por pasajero',
              'Música a bordo del vuelo',
              'Despliegue de lona: TE AMO, FELIZ CUMPLEAÑOS, FELIZ ANIVERSARIO',
              'Visita al Taller de Obsidiana y Artesanía',
            ]

          })
    },*/
    payFlightNow() {
      let currentFlight = this.flightDetails

      this.$router.push({
        name: 'cart',
        params: {
          flight: currentFlight
        }
      })
    }
  }
}
</script>

<style scoped>

</style>