<template>
  <div class="container" v-if="flightDetails">
    <div class="mb-5 mt-5">
      <b-row>

        <b-col class="" lg="8" md="12" xs="12">

<!--          <b-img class="" :src="flightDetails.gallery[0].path_image" fluid></b-img>-->

          <div>
            <ShareActions :flight-details="flightDetails"></ShareActions>
          </div>
          <h1 class="display-2 font-weight-bold">{{ flightDetails.flight_long_name }}</h1>
          <span class="badge text-secondary "><b-icon icon="info-circle"></b-icon> Precio por {{ flightDetails.flight_prices.unit.unit_description }}</span>
          <p class="p-0 m-0 text-danger">Descuento de <s>${{numberFormat(parseInt(flightDetails.flight_prices.first_price_amount), false, '.', ',')}}</s> a sólo:</p>
          <h2 class="display-3">${{numberFormat(parseInt(flightDetails.flight_prices.final_price_amount), false, '.', ',')}} <small>MXN</small></h2>
          <p v-html="flightDetails.flight_description"></p>

          <button @click="payFlightNow()" type="button" class="btn btn-outline-success btn-lg"><b-icon icon="arrow-right"></b-icon> Pagar ahora</button>
          <a @click="openWhatsappLink(flightDetails)" class="d-block mt-3" href="#!">¿Necesitas asistencia personalizada para tu reserva? Contáctanos ahora.</a>

          <div class="mt-5">
            <p>Medios de pago</p>
            <b-img height="50" src="/payments-logos.png"></b-img>
          </div>
        </b-col>

        <b-col class="order-md-0" lg="4" md="12" xs="12">

          <b-img-lazy :alt="getAltImageFlight(flightDetails)" :srcset="flightDetails.gallery[0].path_image" v-bind="mainProps" :src="`/lazy.png`"></b-img-lazy>


          <ExtrasFlight :flight-details="flightDetails"></ExtrasFlight>
        </b-col>

      </b-row>



    </div>
  </div>
</template>

<script>
import ExtrasFlight from "@/views/Internals/Flight/ExtrasFlight.vue";
import ShareActions from "@/views/Internals/Flight/ShareActions.vue";

export default {
  components: {ShareActions, ExtrasFlight},
  props: ['flightDetails'],
  name: 'FlightInfoDesktop',
  data() {
    return {
      //flightDetails: null
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: false,
        blankColor: '#bbb',
        width: 500,
        height: 500,
        class: ''
      },
    }
  },
  mounted() {
    console.log(this.isMobileScreen())
  },
  methods: {
    getAltImageFlight(flightDetails){
      return `${process.env.VUE_APP_APP_NAME} - ${flightDetails.flight_long_name}`
    },
    openWhatsappLink(flightDetails){
      let phoneNumber = process.env.VUE_APP_PHONE_CONTACT_NUMBER
      let messageText = encodeURIComponent(`Hola, quiero asistencia personalizada del vuelo ${flightDetails.flight_long_name} a través de CalliTours México (https://www.callitours.com.mx), ¿me puede brindar más información? Gracias.`)
      window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${messageText}`)
    },
    isMobileScreen() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    numberFormat(number, decimals, dec_point, thousands_point) {

      if (number == null || !isFinite(number)) {
        throw new TypeError("number is not valid");
      }

      if (!decimals) {
        var len = number.toString().split('.').length;
        decimals = len > 1 ? len : 0;
      }

      if (!dec_point) {
        dec_point = '.';
      }

      if (!thousands_point) {
        thousands_point = ',';
      }

      number = parseFloat(number).toFixed(decimals);

      number = number.replace(".", dec_point);

      var splitNum = number.split(dec_point);
      splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
      number = splitNum.join(dec_point);

      return number;
    },
    payFlightNow() {
      let currentFlight = this.flightDetails

      this.$router.push({
        name: 'cart',
        params: {
          flight: currentFlight
        }
      })
    }
  }
}
</script>

<style scoped>

</style>